import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const FormalDresses = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.shopformaldress.com/formal-dresses"
        />
        <title>{descriptionData.formal.pageTitle}</title>
        <meta name="description" content={descriptionData.formal.pageDescription1}/>
      </Helmet>
      <Seo description={descriptionData.formal.pageDescription} />

      <div className="container mt-6 px-12 mx-auto max-w-screen-xl">
                                      <p className="text-sm">Find your perfect formal dress with our new <Link to="/search" className="font-bold">search page </Link></p>
                        </div>

      

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="Formal"/>
      </div>
      <h1 class="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.formal.heading}
        </h1>
        <p>{descriptionData.formal.description1}</p>
        <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <p className="text-l mt-5 mb-5 font-bold">
        Shop Formal Dress introduces a variety of new dress styles every Friday. This provides a fresh selection of fashionable items for customers to browse through. Be sure to check in each week for unique updates to your wardrobe.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "dress_formal" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.formal.pageTitle}
    description={descriptionData.formal.pageDescription}
  />
)

export default FormalDresses
